/* ================================================== */
/* ================== 768px - 991px ================= */
/* ================================================== */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    #sport_8box .item_big .inner_wrapper {
        min-height: 186px;
    }
}

/* ================================================== */
/* ================== 992px - 1199px ================ */
/* ================================================== */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* BOOTSTRAP HOVER DROPDOWN MENU */
@media all and (min-width: 992px) {
    #sub_nav .nav-item .dropdown-menu {
        display: none;
    }
    #sub_nav .nav-item:hover .nav-link {
        // color: #fff;
    }
    #sub_nav .nav-item:hover .dropdown-menu {
        display: block;
    }
    #sub_nav .nav-item .dropdown-menu {
        margin-top: 0;
    }
}
