#news_slider {
    margin-top: 35px;
    margin-bottom: 15px;
    .container {
        padding: 0px;
    }
    .news_slider_items {
        overflow: hidden;
        .tab-pane {
            -webkit-transition: opacity 1.5s linear;
            -moz-transition: opacity 200ms linear;
            -ms-transition: opacity 200ms linear;
            -o-transition: opacity 200ms linear;
            transition: opacity 200ms linear;
            .news_box {
                position: relative;
                padding: 0px;
                padding-top: 3px;
                padding-left: 12px;
                padding-right: 8px;
                .news_box_img_holder {
                    overflow: hidden;
                    position: relative;
                    max-height: 430px;
                    img {
                        width: 100%;
                    }
                    
                    .title{
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        background: rgba(0, 0, 0, 0.8);
                         @include text_data(24px, 1.5, 400, $white);
                        font-family: "Montserrat", sans-serif;
                        margin-bottom: 0;
                        padding: 15px 10px;
                    }
                }
            }
            .news_sidebar {
                text-align: center;
                padding-top: 5px;
                padding-left: 1px;
                padding-right: 25px;
                ul {
                    margin-top: 3px;
                    li {
                        position: relative;
                        width: 100%;
                        margin-bottom: 20px;
                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }
                }
                .news_sidebar_item {
                    margin-bottom: 19px;
                    &:hover {
                        img {
                            box-shadow: 0px 0px 0px 1px $white;
                            -webkit-box-shadow: 0px 0px 0px 1px $white;
                            -moz-box-shadow: 0px 0px 0px 1px $white;
                        }
                    }
                    .text_holder {
                        padding-left: 0px;
                        height: 70px;
                        overflow: hidden;
                        
                        p {
                            text-align: left;
                            font-size: 13px;
                            line-height: 17px;
                            color: #4d4b4b;
                            margin: 0px;
                        }
                    }
                    &.active_news {
                        .img_holder {
                            &::before {
                                content: "";
                                position: absolute;
                                top: 32px;
                                left: 4px;
                                height: 12px;
                                width: 7px;
                                background: url("../images/icons/sidebar_active.png");
                            }
                        }
                        img {
                            box-shadow: 0px 0px 0px 1px $white;
                            -webkit-box-shadow: 0px 0px 0px 1px $white;
                            -moz-box-shadow: 0px 0px 0px 1px $white;
                        }
                        .circle_loader {
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

.circle_loader {
    position: absolute;
    top: 18px;
    left: 33px;
    visibility: hidden;
    .countdown {
        position: relative;
        margin: auto;
        height: 40px;
        width: 40px;
        text-align: center;
        svg {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            transform: rotateY(0deg) rotateZ(270deg);
            circle {
                stroke-dasharray: 113px;
                stroke-dashoffset: 0px;
                stroke-linecap: round;
                stroke-width: 4px;
                stroke: white;
                fill: none;
            }
        }
    }
}

.news_sidebar.paused .circle_loader .countdown svg circle {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
}

@keyframes countdown {
    from {
        stroke-dashoffset: 113px;
    }
    to {
        stroke-dashoffset: 0px;
    }
}
