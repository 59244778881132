.cd-top {
    display: inline-block;
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 999;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background: $black url(../images/cd-top-arrow.svg) no-repeat center 50%;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s;
}
.cd-top.cd-is-visible,
.cd-top.cd-fade-out,
.no-touch .cd-top:hover {
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s;
}
.cd-top.cd-is-visible {
    visibility: visible;
    opacity: 1;
}
.cd-top.cd-fade-out {
    opacity: 0.8;
}
.no-touch .cd-top:hover {
    background-color: #e86256;
    opacity: 1;
}
@media only screen and (min-width: 768px) {
    .cd-top {
        right: 20px;
        bottom: 20px;
    }
}
@media only screen and (min-width: 1024px) {
    .cd-top {
        height: 40px;
        width: 40px;
        right: 25px;
        bottom: 25px;
    }
}
