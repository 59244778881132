.page404{
    padding-top: 50px;
    
    h3{
        color: $blue;
        margin: 15px 0;
        font-size: 2.4rem
    }
    
    h2{
        color: $red;
        margin: 15px 0;
        font-size: 2.75rem;
    }
}