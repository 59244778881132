.container{
    h1{
        @include text_data(24px, 24px, 500, $blue_dark);
        font-family: "Montserrat", sans-serif;
        margin-bottom: 35px;
    }
}

.dedicated_page {
    .left_box {
        .item_box {
            margin-bottom: 25px;
            &.item_big {
                .text_box .title {
                    @include text_data(14px, 16px, 500, $white);
                    font-family: "Montserrat", sans-serif;
                    margin: 0px;
                }
                
                .img_box{
                    height: 300px;
                }
            }
            &.item_small {
                .text_box .title {
                    @include text_data(11px, 13px, 500, $white);
                    font-family: "Montserrat", sans-serif;
                    margin: 0px;
                }
                
                .img_box{
                    height: 150px;
                }
            }
            .img_box {
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                
                
                position: relative;
                .text_box {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    right: 0px;
                    width: 100%;
                    min-height: 65px;
                    background: $gray_4b;
                    padding: 10px;
                    .date {
                        @include text_data(10px, 12px, 500, $white);
                        font-family: "Montserrat", sans-serif;
                        margin: 5px 0 0 0;
                    }
                }
            }
        }
    }
}

.dedicated_8box {
    padding: 50px 0px;
    background: #eee;
    .item_big {
        margin-bottom: 35px;
        .inner_wrapper {
            padding: 15px;
            .text_box {
                .title {
                    @include text_data(24px, 26px, 900, $white);
                    font-family: "Montserrat", sans-serif;
                }
                .link {
                    @include text_data(14px, 14px, bold, $white);
                    font-family: "Montserrat", sans-serif;
                    margin: 0px;
                    margin-top: 30px;
                }
            }
            .icon_box {
                text-align: right;
            }
        }
    }
    .item_small {
        .inner_wrapper {
            padding: 15px;
            .sub_title {
                @include text_data(12px, 14px, bold, $white);
                font-family: "Montserrat", sans-serif;
                margin: 0px;
                margin-top: 25px;
                min-height: 42px;
            }
        }
    }
}

#sport_page {
    .headline {
        @include text_data(24px, 24px, 500, $blue_dark);
        font-family: "Montserrat", sans-serif;
        margin-bottom: 35px;
    }
    .right_box {
        .right_row_item {
            margin-bottom: 30px;
            .img-box {
                padding-right: 0px;
                text-align: center;
            }
            .txt-box,
            .txt-box p {
                @include text_data(12px, 16px, 600, $black1a);
                font-family: "Montserrat", sans-serif;
                margin: 0px;
            }
        }
    }
}
#omladina_page {
    .headline {
        @include text_data(24px, 24px, 500, $red);
        font-family: "Montserrat", sans-serif;
        margin-bottom: 35px;
    }
    .right_box {
        ul {
            padding-right: 90px;
            li {
                margin-bottom: 10px;
                a {
                    @include text_data(12px, 16px, 600, $black1a);
                    font-family: "Montserrat", sans-serif;
                    &:hover {
                        color: $red;
                    }
                }
            }
        }
    }
}
#sport_8box {
    .item_big .inner_wrapper {
        background: $blue_dark;
    }
    .item_small .inner_wrapper {
        background: $blue_dark;
    }
}
#omladina_8box {
    .item_big .inner_wrapper {
        background: $red;
    }
    .item_small .inner_wrapper {
        background: $red;
    }
}

