#sub_nav {
    margin-top: 50px;
    margin-bottom: 5px;
    .nav_wrapper {
        text-align: center;
        padding: 0px;
        ul {
            margin: 0px;
            padding: 0px;
            li {
                float: left;
                .link {
                    @include text_data(14px, 26px, 600, $black16);
                    font-family: "Montserrat", sans-serif;
                    margin: 0px 14px;
                    padding: 0px;
                    &:hover {
                        color: $red;
                    }
                    &.color_item {
                        position: relative;
                        &::before {
                            content: "";
                            position: absolute;
                            top: 6px;
                            right: -11px;
                            width: 7px;
                            height: 12px;
                        }
                        &.sport::after {
                            display: none;
                        }
                        &.sport::before {
                            background: url("../images/icons/sub_nav_blue.png");
                        }
                        &.young::after {
                            display: none;
                        }
                        &.young::before {
                            background: url("../images/icons/sub_nav_red.png");
                        }
                    }
                }
                .dropdown-menu {
                    padding: 10px 0;
                    margin: 0px;
                    // margin-top: 8px;
                    border-radius: 0;
                    .dropdown-item {
                        @include text_data(14px, 26px, 500, $black16);
                        font-family: "Montserrat", sans-serif;
                        &.active,
                        &:active {
                            background-color: transparent;
                        }
                        &:hover {
                            color: $red;
                        }
                    }
                }
            }
        }
    }
}


#sub_nav2{
    margin-top: 50px;
    margin-bottom: 5px;

    //    animations and such
    .dropdown-submenu {
        position: relative;
    }

    .dropdown-submenu a::after {
        transform: rotate(-90deg);
        position: absolute;
        right: 1px;
        top: 16px;
    }

    .dropdown-submenu .dropdown-menu {
        top: 0;
        left: 100%;
        margin-left: .1rem;
        margin-right: .1rem;
    }

    .dropdown:hover>.dropdown-menu {
        display: block;
    }

    .dropdown-submenu:hover>.dropdown-menu {
        display: block;
    }

    .navbar-nav{
        >li{
            >.dropdown-menu{
                margin-top: 0;
            }
        }

        .dropdown-submenu{
            .dropdown-menu{
                margin-left: 0;
            } 
        }
    }


    // styling

    nav{
        padding:0;

        .navbar-nav{

            >li{
                a{
                    @include text_data(14px, 26px, 600, $black16);
                    font-family: "Montserrat", sans-serif;
                    margin: 0px 12px;
                    padding: 0px;
                    text-transform: uppercase;

                    &.nav-link{
                        &:after{
                            margin-left: 0;
                        }

                        &.color_item {
                            position: relative;
                            &::before {
                                content: "";
                                position: absolute;
                                top: 7px;
                                right: -9px;
                                width: 7px;
                                height: 12px;
                            }
                            &.sport::after {
                                display: none;
                            }
                            &.sport::before {
                                background: url("../images/icons/sub_nav_blue.png");
                            }
                            &.young::after {
                                display: none;
                            }
                            &.young::before {
                                background: url("../images/icons/sub_nav_red.png");
                            }
                        }
                    }
                }


                .dropdown-menu{

                    padding: 10px 0;
                    margin: 0px;
                    // margin-top: 8px;
                    border-radius: 0;

                    .dropdown-item{
                        
                         &.nav-link{


                            &:after{
                                margin-left: 5px;
                            }
                        }
                        
                        
                        text-transform: none;
                        margin-left: 0;
                        padding-left: 12px;                     


                        @include text_data(14px, 36px, 500, $black16);
                        font-family: "Montserrat", sans-serif;
                        &.active,
                        &:active {
                            background-color: transparent;
                        }
                        &:hover {
                            color: $red;
                        }

                    }
                }

            }




        }
    }
}