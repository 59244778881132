/* ================================================== */
/* ====================== 911px ===================== */
/* ================================================== */
@media only screen and (max-width: 991px) {
    /* NAVIGATION */
    #main_nav,
    #sub_nav,
    #sub_nav2 {
        display: none;
    }
    .navigacija {
        .navbar-brand {
            position: initial;
            top: 0;
            left: 0;
            .desktop_logo {
                display: none;
            }
            .mobile_logo {
                display: block;
            }
        }
    }
    #mobile_nav {
        display: block;
        .navbar-collapse{
            &.show{
                width: 100%;
            }
        }
        .navigacija {
            background: $red;
            .first_box {
                margin-top: 15px;
                margin-bottom: 15px;
                .social {
                    text-align: center;
                    .fa {
                        @include text_data(16px, 20px, normal, $white);
                        @include transitions;
                        margin: 0px 7px;
                    }
                }
                .links {
                    text-align: center;
                    a {
                        @include text_data(11px, 11px, 500, $white);
                        font-family: "Montserrat", sans-serif;
                        padding: 0 6px;
                        margin: 0 6px;
                        position: relative;
                        &::after {
                            position: absolute;
                            content: "";
                            border-right: 1px solid $white;
                            top: -2px;
                            right: -7px;
                            width: 1px;
                            height: 20px;
                        }
                        &:last-child {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
            .logo_box {
                background: $white;
                text-align: center;
            }
        }
        .navbar {
            background: $blue;
            .navbar-toggler {
                margin-left: auto;
                margin-right: 30px;
            }
            .navbar-nav {
                >li{
                    >a{
                        @include text_data(14px, 16px, 400, $white);
                         font-family: "Montserrat", sans-serif;
                      
                    }
                }
                
                text-align: center;
                .dropdown-menu {
                    text-align: center;
                    margin: 0;
                    border: 0;
                    border-radius: 0;
                    background-color: $blue;
                    border-bottom: 1px solid #444;
                    margin-bottom: 15px;
                    .dropdown-item {
                        @include text_data(12px, 14px, 400, $white);
                        text-align: left;
                           white-space: initial;
                        font-family: "Montserrat", sans-serif;
                    }
                }
            }
        }
/*        .navbar-dark .navbar-nav .nav-link {
            @include text_data(12px, 14px, 400, $white);
            font-family: "Montserrat", sans-serif;
        }*/
    }
    /* NAVIGATION */
    /* FOOTER */
    footer {
        .items_row .footer_item {
            text-align: center;
        }
        .copyright_row .copyright_first {
            line-height: 14px;
        }
        .copyright_row .copyright_second {
            margin-top: 10px;
        }
    }
    /* FOOTER */
    /* HP SOCIAL */
    #hp_social_box .social_item {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    /* HP SOCIAL */
    /* HP 4BOX */
    #hp_box4 {
        .item_big {
            margin-bottom: 20px;
        }
        .item_small {
            margin-bottom: 15px;
        }
    }
    /* HP 4BOX */
    /* HP CATEGORY */
    #hp_category_split {
        .split_box {
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0px;
            }
            .inner_row {
                text-align: center;
            }
        }
        .title {
            text-align: center;
            margin-bottom: 25px;
        }
        .view_all {
            text-align: center;
        }
    }
    /* HP CATEGORY */
    /* HP SLIDER */
    #news_slider {
        .news_slider_items {
            .tab-pane {
                .news_box {
                    padding-left: 15px;
                    padding-right: 15px;
                    .news_box_img_holder{
                        .title{
                            font-size: 12px;  
                        }  
                    } 

                }
                .news_sidebar {
                    padding-left: 15px;
                    padding-right: 15px;
                    .news_sidebar_item.active_news .img_holder::before {
                        top: 25px;
                    }
                    ul {
                        li {
                            margin-bottom: 8px;
                            .sidebar_row {
                                padding: 0px 15px;
                                .img_holder {
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }
        }
        .circle_loader {
            display: none;
            // left: 45px;
        }
    }
    /* HP SLIDER */
    /* HP SOCIAL BOX */
    #hp_social_box {
        .social_item {
            .title {
                text-align: center;
            }
            .wrapper_box {
                max-width: 500px;
                margin: 0 auto;
            }
            .view_all {
                text-align: center;
            }
        }
    }
    /* HP SOCIAL BOX */
    /* STATIC PAGE */
    .static_page {
        .static_page .page_content .text,
        .static_page .page_content .text p {
            line-height: 24px;
        }
        .page_sidebar {
            margin-top: 45px;
            padding-left: 25px;
            padding-right: 25px;
        }
    }
    /* STATIC PAGE */
    /* breadcrumb */
    #mini_nav .breadcrumb .breadcrumb-item a {
        font-size: 12px;
        line-height: 12px;
    }
    /* breadcrumb */
    /* ARTICLES */
    #articles_all {
        .articles_content {
            .headline {
                text-align: center;
            }
            .news_item {
                .img-box {
                    text-align: center;
                }
                .txt-box {
                    .date {
                        text-align: center;
                        margin-top: 8px;
                    }
                    .title {
                        // width: 100%;
                        margin: 0 auto;
                        text-align: center;
                    }
                    .link {
                        position: initial;
                        text-align: center;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
    .articles_content {
        padding-bottom: 0px;
        .articles_sidebar {
            margin-top: 40px;
            .headline {
                text-align: center;
            }
            ul {
                text-align: center;
            }
        }
    }
    /* ARTICLES */
    /* DOCUMENTS */
    #document_page {
        .doc_content {
            .number_row {
                .doc_numb {
                    padding-bottom: 20px;
                    text-align: center;
                }
            }
            .middle_td {
                text-align: center;
                margin-top: 10px;
            }
            .last_td {
                text-align: center;
                margin-top: 10px;
            }
        }
        .doc_sidebar {
            margin-top: 45px;
            padding-left: 25px;
            padding-right: 25px;
        }
    }
    /* DOCUMENTS */
    /* DEDICATED PAGE */
    .dedicated_page {
        .left_box {
            .item_box {
                .img_box {
                    img {
                        width: 100%;
                    }
                    .text_box .date {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    #sport_page .right_box .right_row_item {
        text-align: center;
    }
    #sport_8box .item_small {
        margin-bottom: 15px;
    }
    #omladina_8box .item_small {
        margin-bottom: 15px;
    }
    #omladina_page .right_box ul {
        text-align: center;
        padding-right: 0px;
    }
    .dedicated_8box .item_big {
        margin-bottom: 15px;
    }
    /* DEDICATED PAGE */
}
