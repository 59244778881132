html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    font-family: "Open Sans", sans-serif;
}
a,
a:hover,
a:focus,
a:active {
    text-decoration: none !important;
    outline: none;
    @include transitions;
}
