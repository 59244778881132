#hp_category_split {
    padding: 30px 0px;
    .title {
        @include text_data(24px, 24px, 500, $black_light);
        font-family: "Montserrat", sans-serif;
        margin-bottom: 15px;
        &.title_left {
            color: $blue_title;
        }
        &.title_right {
            color: $red_title;
        }
    }

    .split_box {
        .separator:nth-of-type(3) {
            display: none;
        }
    }

    .inner_row {
        .img_box {
            text-align: center;
        }
        .text_box {
            padding-top: 8px;
            padding-right: 30px;
            .text {
                @include text_data(14px, 18px, 600, $gray_4b);
                font-family: "Montserrat", sans-serif;
                p {
                    @include text_data(14px, 18px, 600, $gray_4b);
                    font-family: "Montserrat", sans-serif;
                }
            }
            .date {
                @include text_data(11px, 12px, 500, $gray_4b);
                font-family: "Montserrat", sans-serif;
                p {
                    @include text_data(11px, 12px, 500, $gray_4b);
                    font-family: "Montserrat", sans-serif;
                }
            }
        }
    }

    .view_all {
        text-align: right;
        margin-top: 6px;
        a {
            @include text_data(14px, 14px, 500, black_light);
            font-family: "Montserrat", sans-serif;
        }
        &.view_all_left a {
            color: $red_title;
        }
        &.view_all_left a {
            color: $blue_title;
        }
    }
}
