#hp_box8 {
    padding: 30px 0px;
    position: relative;
    .container {
        position: relative;
        z-index: 100;
    }
    .row_item {
        margin-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        .inner_item {
            background: $blue_dark;
            position: relative;
            min-height: 200px;
            padding: 10px;
            .title {
                @include text_data(15px, 20px, 600, $white);
                font-family: "Montserrat", sans-serif;
                padding-right: 50px;
            }
            .img_box {
                position: absolute;
                bottom: 10px;
                left: 10px;
                z-index: 10;
            }
            &.inner_item_important {
                background: $red;
                .sub_title {
                    @include text_data(18px, 20px, 900, $white);
                    font-family: "Montserrat", sans-serif;
                    margin-top: 10px;
                }
            }
        }
    }

    .overlay_bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 75px;
   //     z-index: 10;
        background: -moz-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: -webkit-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
    }
}
