@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/font-awesome/fontawesome-webfont.eot');
  src: url('../fonts/font-awesome/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-awesome/fontawesome-webfont.woff2') format('woff2'),
    url('../fonts/font-awesome/fontawesome-webfont.woff') format('woff'),
    url('../fonts/font-awesome/fontawesome-webfont.ttf') format('truetype'),
    url('../fonts/font-awesome/fontawesome-webfont.svg#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
