.static_page {
    padding-top: 20px;
    padding-bottom: 60px;
    position: relative;
    .page_content {
        .headline {
            @include text_data(24px, 24px, 400, $blue_dark);
            font-family: "Montserrat", sans-serif;
            margin-bottom: 35px;
        }
        .text,
        .text p {
            @include text_data(14px, 1.5, 400, $gray_4b);
            font-family: "Montserrat", sans-serif;
        }
        
        .text{
            ul, ol{
                padding-left: 30px; 
                
                li{
                   line-height: 1.5;
                }
            }
            
            ul{
                list-style: disc;
                
            }
            
            ol{
                list-style: decimal;
            }
        }
    }
    .page_sidebar {
        padding-left: 25px;
        padding-right: 125px;
        .headline {
            @include text_data(24px, 24px, 400, $blue_dark);
            font-family: "Montserrat", sans-serif;
            margin-bottom: 35px;
        }
        ul {
            li {
                margin-bottom: 17px;
                a {
                    @include text_data(14px, 20px, 600, $black1a);
                    font-family: "Montserrat", sans-serif;
                    &:hover {
                        color: $red;
                    }
                }
                &.sub_item {
                    margin-left: 30px;
                }
            }
            .inner {
                position: relative;
                overflow: hidden;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 8px;
                    width: 2px;
                    height: calc(100% - 15px);
                    background: $black1a;
                }
            }
        }
    }
    .overlay_bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 150px;
     //   z-index: 10;
        background: -moz-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: -webkit-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
    }
}
