#mobile_nav {
    display: none;
}
.navbar {
    padding: 10px 0px;
    position: relative;
    .container {
        position: relative;
    }
    .extend_top_red {
        background: url("../images/shapes/red_nav.png");
        position: absolute;
        top: -16px;
        left: -400px;
        width: 793px;
        height: 98px;
    }
    .extend_top_blue {
        background: url("../images/shapes/blue_nav.png");
        position: absolute;
        top: -9px;
        left: -330px;
        width: 594px;
        height: 98px;
    }
    .navbar-brand {
        position: absolute;
        top: -65px;
        left: -40px;
        z-index: 100;
        padding: 0px;
        margin: 0px;
        .desktop_logo {
            display: block;
        }
        .mobile_logo {
            display: none;
        }
    }
}

.navbar-dark {
    .navbar-nav {
        .nav-link {
            @include text_data(14px, 14px, 400, $gray_70);
            font-family: "Montserrat", sans-serif;
            margin: 0;
            &:hover {
                color: $red;
            }
        }
    }
}
.navbar-nav {
    .social_item {
        margin: 0px 7px;
        .fa {
            @include text_data(16px, 20px, normal, $gray_70);
            @include transitions;
        }
        &:hover .fa {
            color: $blue;
        }
    }
}

.search_row {
    overflow: hidden;
    .container {
        background: $blue_search;
        position: relative;
        ul {
            margin-bottom: 0px;
            padding: 9px 10px 10px;
            text-align: right;
            li {
                a {
                    @include text_data(14px, 16px, normal, $white);
                    span {
                        position: relative;
                        &::after {
                            content: "";
                            position: absolute;
                            bottom: -4px;
                            left: 0px;
                            right: 0px;
                            margin: 0 auto;
                            width: 65px;
                            height: 1px;
                            background: $white;
                        }
                    }
                }

                button{
                    padding: 0.2rem 0.4rem;
                    font-size: 0.8rem;
                    line-height: 1.5;
                    border-radius: 0.2rem;
                    color: #fff;
                }

                input{
                    background: transparent;
                    color: #fff;
                    border: 0;
                    border-bottom: 1px solid #fff;

                    &::-webkit-input-placeholder {
                        color: rgba(255,255,255,0.7);
                    }

                    &:-ms-input-placeholder {
                        color: rgba(255,255,255,0.7);
                    }

                    &::placeholder {
                        color: rgba(255,255,255,0.7);
                    }
                }
            }
        }
        .extend_blue {
            background: $blue_search;
            position: absolute;
            top: 0px;
            right: -1000px;
            width: 1000px;
            height: 49px;
        }
    }
}
