#document_page {
    padding-top: 20px;
    padding-bottom: 60px;
    position: relative;
    .doc_content {
        .number_row {
            border-bottom: 0px;
            .doc_numb {
                @include text_data(10px, 14px, 500, $gray_70);
                font-family: "Montserrat", sans-serif;
                margin: 0px;
                text-align: right;
                span {
                    @include text_data(14px, 14px, bold, $gray_70);
                }
            }
        }
        .row_item {
            border-bottom: 1px solid #ccc;
            padding: 20px 0px;
            &:last-child {
                border-bottom: 0px;
            }
        }
        .first_td {
            text-align: center;
        }
        .middle_td {
            .title {
                @include text_data(14px, 18px, 600, $gray_4b);
                font-family: "Montserrat", sans-serif;
                margin: 0px;
                padding-bottom: 4px;
            }
            .date {
                @include text_data(10px, 12px, 500, #b7b7b7);
                font-family: "Montserrat", sans-serif;
                margin: 0px;
            }
        }
        .last_td {
            @include text_data(14px, 20px, 600, $blue_dark);
            font-family: "Montserrat", sans-serif;
            text-align: right;
            a {
                @include text_data(14px, 20px, 600, $blue_dark);
                font-family: "Montserrat", sans-serif;
                &:hover {
                    color: $red;
                }
            }
        }
    }
    .doc_sidebar {
        padding-left: 25px;
        padding-right: 125px;
        z-index: 11;
        .headline {
            @include text_data(24px, 24px, 400, $blue_dark);
            font-family: "Montserrat", sans-serif;
            margin-bottom: 35px;
        }
        ul {
            li {
                margin-bottom: 17px;
                a {
                    @include text_data(14px, 20px, 600, $black1a);
                    font-family: "Montserrat", sans-serif;
                    &:hover {
                        color: $red;
                    }
                }
                &.sub_item {
                    margin-left: 30px;
                }

                &.active{
                    a{
                        color: $red;
                    }
                }
            }
            .inner {
                position: relative;
                overflow: hidden;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 8px;
                    width: 2px;
                    height: calc(100% - 15px);
                    background: $black1a;
                }
            }
        }
    }
    .overlay_bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 150px;
        //z-index: 10;
        background: -moz-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
            );
        background: -webkit-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
            );
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
            );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
    }
}
