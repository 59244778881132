.error_body {
    background: url(../images/background/error_bgd.jpg) no-repeat center center /
        cover;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
    .error_box {
        display: table;
        width: 100%;
        height: 100vh;
        .error_box_inner {
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            .main_headline {
                @include text_data(170px, 170px, 600, $white);
                font-family: "Montserrat";
                text-align: center;
            }
            .sub_headline {
                @include text_data(66px, 66px, 500, $white);
                font-family: "Montserrat";
                text-align: center;
                text-transform: uppercase;
                span {
                    @include text_data(24px, 24px, normal, $white);
                    font-size: 24px;
                    display: block;
                }
            }
            .error_links {
                @include text_data(16px, 24px, normal, $white);
                font-family: "Open Sans";
                margin-top: 25px;
                a {
                    @include text_data(16px, 24px, normal, $white);
                    font-family: "Open Sans";
                    &:hover {
                        color: $gray_light;
                    }
                }
            }
        }
    }
}
