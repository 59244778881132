#mini_nav {
    margin-top: 45px;
    margin-bottom: 30px;
    .breadcrumb {
        padding: 0px;
        background: transparent;
        
            @include text_data(14px, 14px, 400, $blue_dark);
            font-family: "Montserrat", sans-serif;
        
        .breadcrumb-item a {
            @include text_data(14px, 14px, 400, $gray_70);
            font-family: "Montserrat", sans-serif;
        }
    }
}
