#thankyou {
    padding: 120px 0px;
    text-align: center;
    position: relative;
    .headline {
        @include text_data(56px, 56px, 600, $gray_4b);
        font-family: "Montserrat", sans-serif;
        text-transform: uppercase;
        padding-bottom: 25px;
        text-align: center;
    }
    .sub_headline {
        @include text_data(26px, 26px, normal, $gray_4b);
        font-family: "Montserrat", sans-serif;
        text-transform: uppercase;
        text-align: center;
    }
    .overlay_bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 150px;
 //       z-index: 10;
        background: -moz-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: -webkit-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
    }
}
