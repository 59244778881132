#hp_social_box {
    padding: 50px 0px;
    .social_item {
        .title {
            @include text_data(25px, 25px, 300, #32383b);
            font-family: "Montserrat", sans-serif;
        }
        .wrapper_box {
            overflow-x: hidden;
            overflow-y: scroll;
            iframe {
                width: 100%;
                margin: 0 auto;
            }
            height: 230px;
            border-top: 1px solid #e9edf0;
            border-bottom: 1px solid #e9edf0;
        }
        .view_all {
            text-align: right;
            margin-top: 15px;
            a {
                @include text_data(10px, 10px, 500, blue_light);
                font-family: "Montserrat", sans-serif;
            }
        }
    }
}
