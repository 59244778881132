#contact_content {
    padding: 40px 0px 60px;
    position: relative;
    .contact_box {
        .section_headline {
            @include text_data(24px, 24px, 500, $blue_dark);
            font-family: "Montserrat", sans-serif;
            margin-bottom: 35px;
            text-align: center;
            text-transform: uppercase;
        }
        form {
            width: 70%;
            margin: 0 auto;
            position: relative;
            z-index: 100;
            // margin-bottom: 40px;
            #btn_send {
                @include text_data(16px, 16px, 600, $white);
                font-family: "Montserrat", sans-serif;
                padding: 10px 50px;
                background: $blue;
                border: 1px solid $blue;
                transition: all 0.3s ease-in-out;
                &:hover {
                    color: $blue;
                    background: transparent;
                }
            }
            .form-control:focus {
                border-color: #ced4da;
                outline: 0;
                box-shadow: none;
            }
            .recaptcha_box .g-recaptcha {
                width: 304px;
                margin: 0 auto;
                padding-bottom: 15px;
            }
        }
    }
    .overlay_bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 150px;
   //     z-index: 10;
        background: -moz-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: -webkit-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
    }
}
