.articles_content {
    padding-top: 20px;
    padding-bottom: 60px;
    position: relative;

    .overlay_bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 150px;
     //   z-index: 10;
        background: -moz-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
            );
        background: -webkit-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
            );
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
            );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
    }
}

.articles_sidebar {
    .headline {
        @include text_data(24px, 24px, 400, $blue_dark);
        font-family: "Montserrat", sans-serif;
        margin-bottom: 35px;
    }
    ul {
        li {
            margin-bottom: 15px;
            a {
                @include text_data(14px, 20px, 600, $black1a);
                font-family: "Montserrat", sans-serif;
                &:hover {
                    color: $red;
                }
            }
        }
    }
    .years_box {
        .year_item {
            padding: 10px;
            .inner_wrapper {
                background: $blue_dark;
                padding: 10px;
                .icon_cal {
                    @include text_data(36px, 40px, 400, $white);
                    margin: 0px;
                    margin-bottom: 10px;
                }
                .year {
                    @include text_data(24px, 24px, 900, $white);
                    font-family: "Montserrat", sans-serif;
                    margin: 0px;
                }
            }
        }
    }
}

#articles_all {
    .articles_content {
        .headline {
            @include text_data(24px, 24px, 400, $blue_dark);
            font-family: "Montserrat", sans-serif;
            margin-bottom: 35px;
        }
        .news_item {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #ccc;
            position: relative;
            .txt-box {
                .date {
                    @include text_data(10px, 12px, 500, #b7b7b7);
                    font-family: "Montserrat", sans-serif;
                    margin: 0px;
                    padding-bottom: 4px;
                }
                .title {
                    @include text_data(14px, 18px, 600, $gray_4b);
                    font-family: "Montserrat", sans-serif;
                    margin: 0px;
                    width: 70%;
                }
                .link {
                    text-align: right;
                    margin: 0px;
                    position: absolute;
                    bottom: 0px;
                    right: 20px;
                    a {
                        @include text_data(14px, 20px, 600, $blue_dark);
                        font-family: "Montserrat", sans-serif;
                        &:hover {
                            color: $red;
                        }
                    }
                }
            }
        }
    }
}
#article_single {
    .single_content {
        .headline {
            @include text_data(24px, 28px, 400, $blue_dark);
            font-family: "Montserrat", sans-serif;
            margin-bottom: 35px;
            width: 80%;
        }
        .img_box {
            margin-bottom: 10px;
        }
        .text,
       .text p {
            @include text_data(14px, 1.5, 400, $gray_4b);
            font-family: "Montserrat", sans-serif;
        }
        
        .text{
            ul, ol{
                padding-left: 30px; 
                
                li{
                   line-height: 1.5;
                }
            }
            
            ul{
                list-style: disc;
                
            }
            
            ol{
                list-style: decimal;
            }
        }

        .text{
            img{
                width: 100%;
            }
            
            .videoWrapper{
                width: 100%;
            }
        }
    }
}

#articles_split {
    // background: #eee;
    padding: 30px 0px;
    .title {
        @include text_data(24px, 24px, 500, $blue_title);
        font-family: "Montserrat", sans-serif;
        margin-bottom: 25px;
    }
    .split_box {
        .separator:nth-of-type(3) {
            display: none;
        }
    }
    .inner_row {
        .img_box {
            text-align: center;
        }
        .text_box {
            padding-top: 8px;
            padding-right: 30px;
            .text {
                @include text_data(14px, 18px, 600, $gray_4b);
                font-family: "Montserrat", sans-serif;
                p {
                    @include text_data(14px, 18px, 600, $gray_4b);
                    font-family: "Montserrat", sans-serif;
                    margin-bottom: 0px;
                    margin-bottom: 8px;
                }
            }
            .date {
                @include text_data(11px, 12px, 500, $gray_4b);
                font-family: "Montserrat", sans-serif;
                p {
                    @include text_data(11px, 12px, 500, $gray_4b);
                    font-family: "Montserrat", sans-serif;
                }
            }
        }
    }

    .view_all {
        text-align: right;
        margin-top: 6px;
        a {
            @include text_data(14px, 14px, 500, black_light);
            font-family: "Montserrat", sans-serif;
        }
        &.view_all_left a {
            color: $blue_title;
        }
        &.view_all_left a {
            color: $red_title;
        }
    }
}

.pagination-holder{
    width: 100%;

    .helper-text{
        color: $blue;
        line-height: 1.5;
        padding-top: 8px;
    }

    nav{
        display: inline-block;
        margin-right: -15px;
    }
}



.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
