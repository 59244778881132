footer {
    padding: 60px 0 30px;
    position: relative;
    .container {
        position: relative;
        z-index: 50;
    }
    .items_row {
        margin-bottom: 35px;
        .footer_item {
            margin-bottom: 15px;
            .icon_box {
                text-align: center;
            }
            .text_box {
                .title {
                    @include text_data(12px, 14px, 600, $gray_999);
                    font-family: "Montserrat", sans-serif;
                    margin: 0;
                    margin-bottom: 3px;
                }
                .sub_title {
                    @include text_data(12px, 14px, 600, $gray_70);
                    font-family: "Montserrat", sans-serif;
                    margin: 0;
                }
            }
        }
    }
    .copyright_row {
        text-align: center;
        .img_box {
            position: relative;
            display: inline-block;
            padding: 0 10px;
            margin: 0 10px;
            &::after {
                position: absolute;
                content: "";
                border-right: 1px solid $blue;
                top: 10px;
                right: -10px;
                width: 1px;
                height: 50px;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
        .copyright_first {
            @include text_data(11px, 11px, 600, $gray_70);
            font-family: "Montserrat", sans-serif;
            margin: 0;
            margin-top: 15px;
        }
        .copyright_second {
            @include text_data(11px, 11px, 600, $gray_999);
            font-family: "Montserrat", sans-serif;
            margin: 0;
            margin-top: 4px;
        }
        .social_box {
            text-align: center;
            margin-top: 20px;
            a {
                margin: 0 10px;
                .fa {
                    @include text_data(26px, 26px, normal, $gray_70);
                    @include transitions;
                }
                &:hover .fa {
                    color: $blue;
                }
            }
        }
    }
    .link_row {
        text-align: center;
        .top_links {
            margin-top: 15px;
            padding-top: 15px;
            margin-bottom: 15px;
            border-top: 1px solid #b7b7b7;
            a {
                @include text_data(11px, 11px, 600, $black16);
                font-family: "Montserrat", sans-serif;
                text-transform: uppercase;
                margin: 0 10px;
                &:hover {
                    color: $blue;
                }
            }
        }
        .bottom_link {
            a {
                @include text_data(11px, 11px, 500, $blue);
                font-family: "Montserrat", sans-serif;
                padding: 0 6px;
                margin: 0 6px;
                position: relative;
                &::after {
                    position: absolute;
                    content: "";
                    border-right: 1px solid $blue;
                    top: -2px;
                    right: -7px;
                    width: 1px;
                    height: 20px;
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
    .overlay_top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 75px;
        z-index: 10;
        background: -moz-linear-gradient(
            top,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
    }
    .overlay_bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 75px;
        // z-index: 10;
        background: -moz-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: -webkit-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
    }
}
