#search_results {

    .tab-content{
        width: 100%;
        padding: 15px !important;
    }

    .headline {
        margin-top: 40px;
        @include text_data(24px, 24px, 400, $blue_dark);
        font-family: "Montserrat", sans-serif;
        margin-bottom: 35px;
    } 
    
    .articles_content{
        padding-bottom: 10px;
    }


    .search-page-link{
        color: $blue;
        margin: 10px;
        font-size: 20px;
        padding-left: 40px;
        font-weight: 600;
    }
    
    .documents-table{
        thead{
            th{
                background: #eee;
            }
        }
    }
}
