@mixin transitions {
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

@mixin text_data($font, $line_height, $weight, $color) {
    font-size: $font;
    line-height: $line_height;
    font-weight: $weight;
    color: $color;
}