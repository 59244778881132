#hp_box4 {
    padding: 50px 0px;
    background: #eee;
    .item_big {
        margin-bottom: 35px;
        .inner_wrapper {
            background: $red;
            padding: 15px;
            min-height: 180px;
            .text_box {
                .sub_title {
                    @include text_data(15px, 15px, 600, $white);
                    font-family: "Montserrat", sans-serif;
                }
                .title {
                    @include text_data(18px, 22px, 900, $white);
                    font-family: "Montserrat", sans-serif;
                }
            }
            .icon_box {
                text-align: center;
                img {
                    padding-top: 30px;
                }
            }
        }
    }
    .item_small {
        .inner_wrapper {
            background: $blue_dark;
            padding: 15px;
            .sub_title {
                @include text_data(15px, 15px, 600, $white);
                font-family: "Montserrat", sans-serif;
                margin-top: 15px;
            }
            .title {
                @include text_data(23px, 23px, 900, $white);
                font-family: "Montserrat", sans-serif;
            }
        }
    }
}
