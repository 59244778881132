$white: #fff;
$white_light: #f1f1f1;

$black: #000;
$black_light: #333;
$black16: #161616;
$black1a: #1a1a1a;

$gray_light: #85868c;
$gray_70: #707070;
$gray_999: #999999;
$gray_4b: #4d4b4b;

$blue: #013767;
$blue_light: #3682bc;
$blue_dark: #26557f;
$blue_title: #0c69a5;
$blue_search: #25527b;

$red: #c6363d;
$red_title: #cf0929;
