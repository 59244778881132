/* ====================================================================================================== */
/* ============================================== OPEN SANS ============================================= */
/* ====================================================================================================== */
@font-face {
    font-family: "Open Sans";
    src: url("../fonts/opensans/OpenSans-Regular.eot");
    src: url("../fonts/opensans/OpenSans-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/opensans/OpenSans-Regular.woff2") format("woff2"),
        url("../fonts/opensans/OpenSans-Regular.woff") format("woff"),
        url("../fonts/opensans/OpenSans-Regular.ttf") format("truetype"),
        url("../fonts/opensans/OpenSans-Regular.svg#OpenSans-Regular")
            format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("../fonts/opensans/OpenSans-Bold.eot");
    src: url("../fonts/opensans/OpenSans-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/opensans/OpenSans-Bold.woff2") format("woff2"),
        url("../fonts/opensans/OpenSans-Bold.woff") format("woff"),
        url("../fonts/opensans/OpenSans-Bold.ttf") format("truetype"),
        url("../fonts/opensans/OpenSans-Bold.svg#OpenSans-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("../fonts/opensans/OpenSans-ExtraBold.eot");
    src: url("../fonts/opensans/OpenSans-ExtraBold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/opensans/OpenSans-ExtraBold.woff2") format("woff2"),
        url("../fonts/opensans/OpenSans-ExtraBold.woff") format("woff"),
        url("../fonts/opensans/OpenSans-ExtraBold.ttf") format("truetype"),
        url("../fonts/opensans/OpenSans-ExtraBold.svg#OpenSans-ExtraBold")
            format("svg");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("../fonts/opensans/OpenSans-Light.eot");
    src: url("../fonts/opensans/OpenSans-Light.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/opensans/OpenSans-Light.woff2") format("woff2"),
        url("../fonts/opensans/OpenSans-Light.woff") format("woff"),
        url("../fonts/opensans/OpenSans-Light.ttf") format("truetype"),
        url("../fonts/opensans/OpenSans-Light.svg#OpenSans-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("../fonts/opensans/OpenSans-SemiBold.eot");
    src: url("../fonts/opensans/OpenSans-SemiBold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/opensans/OpenSans-SemiBold.woff2") format("woff2"),
        url("../fonts/opensans/OpenSans-SemiBold.woff") format("woff"),
        url("../fonts/opensans/OpenSans-SemiBold.ttf") format("truetype"),
        url("../fonts/opensans/OpenSans-SemiBold.svg#OpenSans-SemiBold")
            format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* ====================================================================================================== */
/* ============================================= Montserrat ============================================= */
/* ====================================================================================================== */
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Regular.eot");
    src: url("../fonts/montserrat/Montserrat-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/montserrat/Montserrat-Regular.woff2") format("woff2"),
        url("../fonts/montserrat/Montserrat-Regular.woff") format("woff"),
        url("../fonts/montserrat/Montserrat-Regular.ttf") format("truetype"),
        url("../fonts/montserrat/Montserrat-Regular.svg#Montserrat-Regular")
            format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Medium.eot");
    src: url("../fonts/montserrat/Montserrat-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/montserrat/Montserrat-Medium.woff2") format("woff2"),
        url("../fonts/montserrat/Montserrat-Medium.woff") format("woff"),
        url("../fonts/montserrat/Montserrat-Medium.ttf") format("truetype"),
        url("../fonts/montserrat/Montserrat-Medium.svg#Montserrat-Medium")
            format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-SemiBold.eot");
    src: url("../fonts/montserrat/Montserrat-SemiBold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/montserrat/Montserrat-SemiBold.woff2") format("woff2"),
        url("../fonts/montserrat/Montserrat-SemiBold.woff") format("woff"),
        url("../fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype"),
        url("../fonts/montserrat/Montserrat-SemiBold.svg#Montserrat-SemiBold")
            format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Bold.eot");
    src: url("../fonts/montserrat/Montserrat-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/montserrat/Montserrat-Bold.woff2") format("woff2"),
        url("../fonts/montserrat/Montserrat-Bold.woff") format("woff"),
        url("../fonts/montserrat/Montserrat-Bold.ttf") format("truetype"),
        url("../fonts/montserrat/Montserrat-Bold.svg#Montserrat-Bold")
            format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Light.eot");
    src: url("../fonts/montserrat/Montserrat-Light.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/montserrat/Montserrat-Light.woff2") format("woff2"),
        url("../fonts/montserrat/Montserrat-Light.woff") format("woff"),
        url("../fonts/montserrat/Montserrat-Light.ttf") format("truetype"),
        url("../fonts/montserrat/Montserrat-Light.svg#Montserrat-Light")
            format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Black.eot");
    src: url("../fonts/montserrat/Montserrat-Black.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/montserrat/Montserrat-Black.woff2") format("woff2"),
        url("../fonts/montserrat/Montserrat-Black.woff") format("woff"),
        url("../fonts/montserrat/Montserrat-Black.ttf") format("truetype"),
        url("../fonts/montserrat/Montserrat-Black.svg#Montserrat-Black")
            format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: "Montserrat";
//     src: url("../fonts/montserrat/Montserrat-ExtraBold.eot");
//     src: url("../fonts/montserrat/Montserrat-ExtraBold.eot?#iefix")
//             format("embedded-opentype"),
//         url("../fonts/montserrat/Montserrat-ExtraBold.woff2") format("woff2"),
//         url("../fonts/montserrat/Montserrat-ExtraBold.woff") format("woff"),
//         url("../fonts/montserrat/Montserrat-ExtraBold.ttf") format("truetype"),
//         url("../fonts/montserrat/Montserrat-ExtraBold.svg#Montserrat-ExtraBold")
//             format("svg");
//     font-weight: 800;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Montserrat";
//     src: url("../fonts/montserrat/Montserrat-ExtraLight.eot");
//     src: url("../fonts/montserrat/Montserrat-ExtraLight.eot?#iefix")
//             format("embedded-opentype"),
//         url("../fonts/montserrat/Montserrat-ExtraLight.woff2") format("woff2"),
//         url("../fonts/montserrat/Montserrat-ExtraLight.woff") format("woff"),
//         url("../fonts/montserrat/Montserrat-ExtraLight.ttf") format("truetype"),
//         url("../fonts/montserrat/Montserrat-ExtraLight.svg#Montserrat-ExtraLight")
//             format("svg");
//     font-weight: 200;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Montserrat";
//     src: url("../fonts/montserrat/Montserrat-Thin.eot");
//     src: url("../fonts/montserrat/Montserrat-Thin.eot?#iefix")
//             format("embedded-opentype"),
//         url("../fonts/montserrat/Montserrat-Thin.woff2") format("woff2"),
//         url("../fonts/montserrat/Montserrat-Thin.woff") format("woff"),
//         url("../fonts/montserrat/Montserrat-Thin.ttf") format("truetype"),
//         url("../fonts/montserrat/Montserrat-Thin.svg#Montserrat-Thin")
//             format("svg");
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
// }
